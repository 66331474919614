<template>
    <div>
        <div class="w-full px-8">
            <div v-if="!lastProductionDeploy?.updated_at || !lastStagingDeploy?.updated_at" class="mt-8 -mb-4 w-full flex justify-between  border border-red-300 bg-warning-deploy px-4 py-3">
              <div class="flex justify-between">
                <icon name="alert" class="w-4 h-4 mr-2 icon-warning"></icon>
                  <p class="font-inter text-white text-xs">
                    <span class="font-bold">production </span>
                    <span >or </span>
                    <span class="font-bold">staging </span>
                    <span >website have not yet been deployed for the first time.</span>
                  </p>
              </div>
              <div>
                <modal-link route="deploys.create" :params="{id: website.id}"
                                      class="font-inter text-white text-xs align-top font-semibold underline ">
                              click here to deploy now!
                  </modal-link>
              </div>
            </div>
        </div>
        <div class="w-full px-8">
          <div v-if="!hasHomePage" class="mt-8 -mb-4 w-full flex justify-between  border border-red-300 bg-warning-deploy px-4 py-3">
            <div class="flex justify-between">
              <icon name="alert" class="w-4 h-4 mr-2 icon-warning"></icon>
                <p class="font-inter text-white text-xs">
                  <span >the website does not have a </span>
                  <span class="font-bold">home </span>
                  <span >page defined.</span>
                </p>
            </div>
          </div>
        </div>
        <div class="w-full px-8">
            <div v-if="pagesNotDeployedToStaging.length || pagesNotDeployedToProduction.length" class="mt-8 -mb-4 w-full flex justify-center items-center border border-yellow-200 bg-yellow-100 px-4 py-3">
                <p class="font-inter text-yellow-300 text-sm">
                    you have changes that have not been deployed to 
                    <span v-if="pagesNotDeployedToStaging.length" class="font-bold">staging </span>
                    <span v-if="pagesNotDeployedToStaging.length && pagesNotDeployedToProduction.length">and </span>
                    <span v-if="pagesNotDeployedToProduction.length" class="font-bold">production</span>
                </p>
            </div>
        </div>
        <div class="mt-8 w-full flex justify-between px-8">
            <div class="flex-1 relative mr-4 p-4 border border-highlight-300 bg-highlight-100">
                <h1 class="text-highlight-base font-600 tracking-wide text-sm">
                    <a :href="`https://${website.production_url}`" target="_blank" class="block truncate hover:underline">production</a>
                </h1>
                <p class="text-highlight-700 tracking-wide text-xs font-500">
                    <a :href="`https://${website.production_url}`" target="_blank" class="block truncate hover:underline">{{ website.production_url }}</a>
                </p>
                <div class="flex items-start mt-3" v-if="website.is_domain_validated">
                    <icon name="clock" class="w-4 h-4 mr-1 text-gray-500"></icon>
                    <div class="text-gray-500 font-500 tracking-wide text-xs"><span>last deployed: </span><br />
                        <span  v-if="!lastProductionDeploy" class="text-gray-700 font-600 text-xs">never</span>
                        <span  v-if="lastProductionDeploy" class="text-gray-700 font-600 text-xs">{{ formatDate(lastProductionDeploy.updated_at) }}</span>
                    </div>
                </div>
                <modal-link route="websites.domains" :params="{ id: $route.params.id }"  v-if="!website.is_domain_validated">
                    <div class="flex cursor-pointer items-center mt-3 border border-yellow-200 bg-yellow-100 px-4 py-3">
                        <icon name="alert" class="w-4 h-4 mr-1 text-yellow-200"></icon>
                        <p class="font-inter text-yellow-300 text-xs font-semibold">Click here to configure domain.</p>
                    </div>
                </modal-link>
            </div>
            <div class="flex-1 p-4 border-2 border-gray-200 bg-gray-50">
                <h1 class="text-highlight-500 font-600 tracking-wide text-sm">
                    <a :href="`https://${website.staging_url}`" target="_blank" class="block truncate hover:underline">staging</a>
                </h1>
                <p class="text-gray-500 tracking-wide text-xs font-500">
                    <a :href="`https://${website.staging_url}`" target="_blank" class="block truncate hover:underline">{{ website.staging_url }}</a>
                </p>
                <div class="flex items-start mt-3">
                    <icon name="clock" class="w-4 h-4 mr-1 text-gray-500"></icon>
                    <div class="text-gray-500 font-500 tracking-wide text-xs"><span>last deployed: </span><br />
                        <span  v-if="!lastStagingDeploy" class="text-gray-700 font-600 text-xs">never</span>
                        <span  v-if="lastStagingDeploy" class="text-gray-700 font-600 text-xs">{{ formatDate(lastStagingDeploy.updated_at) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Icon from "@/components/ui/Icon";
    import {mapActions, mapGetters} from "vuex";
    import ModalLink from "@/components/ui/modals/ModalLink";

    export default {
        components: {Icon, ModalLink},
        props: {
            website: {
                type: Object
            }
        },
        data: () => {
          return {
            hasHomePage: false,
          }
        },
        computed: {
            ...mapGetters({
                getStagingDeploys: 'deploys/getStagingDeploys',
                getProductionDeploys: 'deploys/getProductionDeploys',
                getPagesByWebsite: 'pages/getPagesByWebsite',
            }),

            lastStagingDeploy() {
                if (this.getStagingDeploys.length) {
                    return this.getStagingDeploys[0]
                }
                return null
            },

            lastProductionDeploy() {
                if (this.getProductionDeploys.length) {
                    return this.getProductionDeploys[0]
                }
                return null
            },

            pagesNotDeployedToStaging() {
                if (this.getStagingDeploys.length) {
                    return this.getPagesByWebsite(this.website.id).filter(page => moment(page.updated_at) > moment(this.lastStagingDeploy.updated_at))
                }
                return []
            },

            pagesNotDeployedToProduction() {
                if (this.getProductionDeploys.length) {
                    return this.getPagesByWebsite(this.website.id).filter(page => moment(page.updated_at) > moment(this.lastProductionDeploy.updated_at))
                }
                return []
            }
        },
        methods: {
            ...mapActions({
                loadDeploymentsInStore: 'deploys/loadDeploys'
            }),

            formatDate(val) {
                return moment(val).format('M/D/YYYY h:mm a');
            },
            fetchWebsiteHomePage() {
              return this.$cmsDataProvider.get('websiteHasHomePage', {websiteId: this.website.id}).then((response) => {
                  if(response.status == 'ok')
                    this.hasHomePage = true;
              })
            },
        },
        mounted() {
            this.$cmsDataProvider.getList('deployments', { websiteId: this.website.id })
                .then((response) => {
                    this.loadDeploymentsInStore(response);
                })

            this.fetchWebsiteHomePage();
        },
    }
</script>

<style scoped>
  .bg-warning-deploy{
    background: #c77266;
  }

  .icon-warning{
    mix-blend-mode: color-dodge !important;
    filter: invert(100%) !important;
  }
</style>