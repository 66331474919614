export const reorderWidgetsByGroup = unorderedWidgets => {
    // Ordered groups based on their typical location in a website page.
    let orderedGroups = [
        'Nav Bars',
        'Hero Section',
        'Amenities',
        'Floor plans',
        'Neighborhood',
        'Featured Content Block',
        'Image Slider',
        'Cta',
        'Contact',
        'Footer',
    ];
    let orderedWidgets = [];

    orderedGroups.forEach(group => {
        unorderedWidgets.forEach(widget => {
            if (widget.group.toLowerCase() === group.toLowerCase()) orderedWidgets.push(widget);
        });
    });

    return orderedWidgets;
};
