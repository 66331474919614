<template>
<div>
    <div class="flex w-full items-center justify-center">
        <div class="flex w-full md:w-1/3 items-center justify-center">
            <icon name="user" class="h-8 w-auto mr-4 text-highlight-base"></icon>
            <div class="flex flex-col">
                <h1 class="text-4xl text-highlight-base font-frank font-bold">{{ totalVisitors }}</h1>
                <p class="-mt-2 font-frank font-500 text-highlight-textLarge tracking-wider text-gray-500">visitors</p>
            </div>
        </div>
        <div class="flex w-full md:w-1/3 items-center justify-center">
            <icon name="eye" class="h-6 w-auto mr-4 text-highlight-base"></icon>
            <div class="flex flex-col">
                <h1 class="text-4xl text-highlight-base font-frank font-bold">{{ totalPageviews }}</h1>
                <p class="-mt-2 font-frank font-500 text-highlight-textLarge tracking-wider text-gray-500">pageviews</p>
            </div>
        </div>
        <div class="flex w-full md:w-1/3 items-center justify-center">
            <icon name="page" class="h-6 w-auto mr-4 text-highlight-base"></icon>
            <div class="flex flex-col">
                <h1 class="text-4xl text-highlight-base font-frank font-bold">{{ leads }}</h1>
                <p class="-mt-2 font-frank font-500 text-highlight-textLarge tracking-wider text-gray-500">leads</p>
            </div>
        </div>
    </div>
    <div class="mt-8 px-8">
        <canvas class="w-full" id="analytics-chart"></canvas>
    </div>
</div>
</template>

<script>
import Moment from 'moment';
import Chart from "chart.js/auto";
import Icon from "@/components/ui/Icon";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels)

export default {
    components: {Icon},
    props: {
        website: {
            type: Object
        },
        customer: {
            type: String
        }
    },
    data() {
        return {
            loading: true,
            analyticsData: {
                requests: 0,
                pageviews: 0,
                leads: 0
            },
            leads: 0,
            chartData: {
                type: "line",
                labels: [],
                data: {
                    datasets: [
                    {
                        label: "Monthly Traffic",
                        // one line graph
                        data: [0,0,0,0,0,0,0,0,0,0,0,0],
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        borderColor: "#3498DB",
                    },
                    ],
                },
                options: {
                    plugins: {
                        // Change options for ALL labels of THIS CHART
                        datalabels: {
                            display: false,
                        },
                        legend: {
                            display: true,
                        }
                    },
                    scaleShowValues: true,
                    scales: {
                        y: {
                            display: false
                        },
                        x: {
                            gridLines: {
                                drawBorder: false,
                                color: 'rgba(226,226,226,.5)',
                                tickColor: '#3498DB'
                            },
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            }
        }
    },
    created() {
        // @TODO: This method below can be refactored for 
        // this apps setup to pull the data
        this.fetchData()
        setTimeout(() => { }, 1500)
        
    },
    mounted() {
        this.$cmsDataProvider.get('analyticsLeadInfo', { websiteId: this.website.id })
            .then(response => this.leads = response.length)
    },
    methods: {
        // @TODO: refactor for current setup
        
        fetchData() {
            this.$cmsDataProvider.get('analyticsSiteInfo', { websiteId: this.website.id })
                .then((response) => {
                    this.analyticsData = response;
                    this.chartData.data.labels = []
                    this.chartData.data.datasets.data = []
                    if(this.analyticsData) {
                        
                        let start = Moment().subtract(12, 'months')
                        let count = 1
                        while(count <= 12) {
                            this.chartData.data.labels.push(start.add(1, 'months').format('MMM'))
                            count++
                        }
                        this.chartData.data.labels.forEach((labelMonth, idx) => {
                            this.analyticsData.map(series => {
                                let month = Moment(series.day).format('MMM')
                                if(month == labelMonth) {
                                    this.chartData.data.datasets[0].data[idx] += series.pageviews
                                }
                            })
                        })
                        this.createChart('analytics-chart', this.chartData)
                    }
                    this.loading = false
                })
        },
        
        createChart(chartId, chartData) {
            const ctx = document.getElementById(chartId);
            return new Chart(ctx, {
                plugins: [ChartDataLabels],
                type: chartData.type,
                data: chartData.data,
                options: chartData.options,
            });
        }
    },
    computed: {
        totalPageviews() {
            if(this.analyticsData.length) {
                return this.analyticsData.reduce(function(prev, cur) {
                    return prev + cur.pageviews
                }, 0)
            } 
            return 0
        },
        totalVisitors() {
            if(this.analyticsData.length) {
                return this.analyticsData.reduce(function(prev, cur) {
                    return prev + cur.visitors
                }, 0)
            }
            return 0
        }
    },
}
</script>