<template>
    <div v-if="website">
        <loader :loading="loading" :backdrop="true" />
        <div class="flex min-h-0 md:min-h-full" v-if="!loading">
            <div class="w-full md:w-3/4 order-last md:order-first">
                <analytics :customer="customerId" :website="website"></analytics>
                <div  v-if="hasAccessTo('MIA')" class="mx-8 mt-4 p-2 mt-2 -mb-4 bg-gray-50 border border-gray-200 flex items-center">
                    <toggle-input @input="saveDigitalHuman()" v-model="website.enable_digital_human" />
                    <div class="pl-4 text-left">
                        <p class="text-sm font-semibold font-inter mb-1 text-highlight-textSmall">enable digital human</p>
                        <p class="text-blue-600 text-xs -mt-1">enabling will show the digital human module on your website.</p>
                    </div>
                </div>
                <deployment-cards :website="website"></deployment-cards>
            </div>
            <div
                class="w-full flex flex-col p-4 px-6 md:w-1/4 order-first md:order-last bg-highlight-100 min-h-0 md:min-h-full">
                <div class="flex items-center justify-between">
                    <div class="flex">
                        <p class="text-highlight-textLarge font-600 tracking-wide text-xl">pages</p>
                    </div>
                    <div class="flex items-center">
                        <modal-link route="pages.create" :params="{websiteId: this.website.id}"
                                    class="text-highlight-textSmall text-sm font-600">
                            add
                            <icon name="plus" class="w-4 h-4 ml-1 inline"></icon>
                        </modal-link>
                    </div>
                </div>
                <ul class="mt-4">
                    <li v-for="(page,index) in getPagesByWebsite(website.id)" :key="index">
                        <modal-link route="pages.show" :params="{websiteId: website.id, pageSlug: page.url_alias}"
                                    class="text-highlight-textSmall text-sm font-bold">
                            {{ page.website_pages_name.toLowerCase() }}
                        </modal-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from "@/mixins/Auth";
import Icon from "@/components/ui/Icon";
import ToggleInput from "@/components/ui/ToggleInput";
import Loader from "@/components/ui/Loader";
import {mapActions, mapGetters} from "vuex";
import NotifyMixin from "@/mixins/NotifyMixin";
import Analytics from "@/components/ui/Analytics";
import AuthMixin from '@/components/auth/AuthMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import ModalLink from "@/components/ui/modals/ModalLink";
import {reorderWidgetsByGroup} from "@/utils/CmsWidgets";
import DeploymentCards from "@/components/cms/websites/DeploymentCards";

export default {
    mixins: [ModalNavigation, Auth, NotifyMixin, AuthMixin],
    components: { Analytics, Icon, ModalLink, DeploymentCards, ToggleInput, Loader},
    data: () => {
        return {
            website: null,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            getPagesByWebsite: 'pages/getPagesByWebsite',
        }),
    },
    methods: {
        ...mapActions({
            loadPagesInStore: 'pages/loadPages',
            loadWidgetsInStore: 'widgets/loadWidgets',
            loadAmenitiesIconsInStore: 'amenities/loadAmenitiesIcons',
            loadCommunityAmenitiesTypesInStore: 'amenities/loadCommunityAmenitiesTypes',
            loadCustomerAmenitiesInStore: 'amenities/loadCustomerAmenities',
            loadGlobalTemplatesInStore: 'templates/loadGlobalTemplates',
            loadCustomerPagesWidgetsInStore: 'pages_widgets/loadPagesWidgets',
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
            updateWebsiteInStore: 'websites/updateWebsite',
            loadFloorplansInStore: 'floorplans/loadFloorplans',
            addWebsiteInfo: 'website_info/addWebsiteInfo',
            clearWebsiteInformation: 'website_info/clearWebsiteInformation',
        }),
        fetchPages() {
            return this.$cmsDataProvider.getList('websitePages', { websiteId: this.website.id })
                .then(response => {
                    this.loadPagesInStore(response);
                })
                .catch((err) => this.notifyError(err));
        },
        fetchWidgets() {
            return this.$cmsDataProvider.getList('widgets')
                .then(response => {
                    this.loadWidgetsInStore(reorderWidgetsByGroup(response));
                })
                .catch((err) => this.notifyError(err));
        },
        fetchAmenitiesIcons() {
            return this.$cmsDataProvider.getList('amenitiesIcons')
                .then(response => {
                    this.loadAmenitiesIconsInStore(response);
                })
                .catch((err) => this.notifyError(err));
        },
        fetchCommunityAmenitiesTypes() {
            return this.$cmsDataProvider.getList('communityAmenitiesTypes')
                .then(response => {
                    this.loadCommunityAmenitiesTypesInStore(response);
                })
                .catch((err) => this.notifyError(err));
        },
        fetchCustomerAmenities() {
            return this.$cmsDataProvider.getList('customerAmenities')
                .then(response => {
                    this.loadCustomerAmenitiesInStore(response);
                })
                .catch((err) => this.notifyError(err));
        },
        fetchGlobalTemplates() {
            return this.$cmsDataProvider.getList('globalTemplates')
                .then(response => {
                    this.loadGlobalTemplatesInStore(response);
                })
                .catch((err) => this.notifyError(err));
        },
        fetchCustomerPagesWidgets() {
            return this.$cmsDataProvider.getList('customerPagesWidgetsByWebsite', { websiteId: this.website.id })
                .then(response => {
                    this.loadCustomerPagesWidgetsInStore(response);
                })
                .catch((err) => this.notifyError(err));
        },
        fetchFloorplans() {
            return this.$cmsDataProvider.get('floorplans', {websiteId: this.website.id}).then((response) => {
                this.loadFloorplansInStore(response);
            })
        },
        saveDigitalHuman() {
            const payload = {};
            payload.production_url = this.website.production_url;
            payload.customers_id = this.website.customers_id;
            payload.communities_id = this.website.communities_id;
            payload.id = this.website.id;
            payload.enable_digital_human = (this.website.enable_digital_human == false)?true : false;
            payload.custom_links = this.website.custom_links;

            this.$cmsDataProvider.update('updateWebsite', { data: payload, id: this.website.id })
                .then(() => {
                    this.clearWebsiteInformation();
                    this.getWebsiteInfo();
                    this.notifySuccess('The digital human setting was updated successfully');
                }).catch(() => {
                this.notifyError('There was an error saving the information');
            });
        },
        async getWebsiteInfo() {
            await this.$cmsDataProvider.get('websitebyId', {websiteId: this.$route.params.id})
            .then((response) => {
                if(!response.message) {
                    this.website = response
                    this.addWebsiteInfo(response);
                }else{
                    this.notifyError('Website not found');
                }
            }).catch(() => {
                this.notifyError('There was an error getting the website ');
                this.website = null
            });
        }
    },
    async mounted() {
        await this.getWebsiteInfo()
        this.loading = true;
         Promise.all([
            this.fetchPages(),
            this.fetchWidgets(),
            this.fetchAmenitiesIcons(),
            this.fetchGlobalTemplates(),
            this.fetchCustomerAmenities(),
            this.fetchCustomerPagesWidgets(),
            this.fetchCommunityAmenitiesTypes(),
            this.fetchFloorplans(),
        ])
        .then( () => {
            this.loading = false;
        })

        if(this.website?.production_url)
            this.setActiveModalTitle(this.website.production_url);
    },
}
</script>

<style scoped>
.dashboard-link {
    @apply text-blue-900 tracking-wider mb-2 font-semibold text-sm cursor-pointer;
}
</style>
